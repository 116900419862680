
export const places = [
    {
        name: "Santa Eulalia",
        code: "STA",
        destinations: [
            { name: "Formentera", code: "FOR" },
            { name: "Ibiza", code: "IBZ" },
            { name: "Es Canar", code: "ESC" },
            { name: "Cala Llonga", code: "CLL" },
            { name: "Cala Pada", code: "CPD" },
        ],
    },
    {
        name: "Ibiza",
        code: "IBZ",
        destinations: [
            { name: "Santa Eulalia", code: "STA" },
            { name: "Es Canar", code: "ESC" },
            { name: "Cala Llonga", code: "CLL" },
            { name: "Cala Pada", code: "CPD" },
        ],
    },
    {
        name: "Cala Pada",
        code: "CPD",
        destinations: [
            { name: "Formentera", code: "FOR" },
            { name: "Ibiza", code: "IBZ" },
            { name: "Santa Eulalia", code: "STA" },
            { name: "Cala Llonga", code: "CLL" },
        ],
    },
    {
        name: "Es Canar",
        code: "ESC",
        destinations: [
            { name: "Formentera", code: "FOR" },
            { name: "Ibiza", code: "IBZ" },
            { name: "Santa Eulalia", code: "STA" },
            { name: "Cala Llonga", code: "CLL" },
        ],
    },
    {
        name: "Cala Llonga",
        code: "CLL",
        destinations: [
            { name: "Formentera", code: "FOR" },
            { name: "Ibiza", code: "IBZ" },
            { name: "Es Canar", code: "ESC" },
            { name: "Santa Eulalia", code: "STA" },
            { name: "Cala Pada", code: "CPD" },
        ],
    }
];

const _places = [
    {
        name: "Santa Eulalia",
        code: "STA",
        destinations: [
            { name: "Formentera", code: "FOR" },
            { name: "Ibiza", code: "IBZ" },
            { name: "Es Canar", code: "ESC" },
            { name: "Cala Llonga", code: "CLL" },
            { name: "Cala Pada", code: "CPD" },
        ],
    },
    {
        name: "Ibiza",
        code: "IBZ",
        destinations: [
            { name: "Santa Eulalia", code: "STA" },
            { name: "Es Canar", code: "ESC" },
            { name: "Cala Llonga", code: "CLL" },
            { name: "Cala Pada", code: "CPD" },
        ],
    },
    {
        name: "Cala Pada",
        code: "CPD",
        destinations: [
            { name: "Formentera", code: "FOR" },
            { name: "Ibiza", code: "IBZ" },
            { name: "Santa Eulalia", code: "STA" },
            { name: "Cala Llonga", code: "CLL" },
        ],
    },
    {
        name: "Es Canar",
        code: "ESC",
        destinations: [
            { name: "Formentera", code: "FOR" },
            { name: "Ibiza", code: "IBZ" },
            { name: "Santa Eulalia", code: "STA" },
            { name: "Cala Llonga", code: "CLL" },
        ],
    },
    {
        name: "Cala Llonga",
        code: "CLL",
        destinations: [
            { name: "Formentera", code: "FOR" },
            { name: "Ibiza", code: "IBZ" },
            { name: "Es Canar", code: "ESC" },
            { name: "Santa Eulalia", code: "STA" },
            { name: "Cala Pada", code: "CPD" },
        ],
    },
    {
        name: "Es Figueral",
        code: "FIG",
        destinations: [
            { name: "Formentera", code: "FOR" },
            { name: "Ibiza", code: "IBZ" },
            { name: "Santa Eulalia", code: "STA" },
        ],
    },
    {
        name: "Formentera",
        code: "FOR",
        destinations: [
            { name: "Santa Eulalia", code: "STA" },
            { name: "Es Canar", code: "ESC" },
            { name: "Cala Llonga", code: "CLL" },
            { name: "Cala Pada", code: "CPD" },
        ],
    },
];

export const formentera_only = [
    {
        name: "Santa Eulalia",
        code: "STA",
        destinations: [
            { name: "Formentera", code: "FOR" },
        ],
    },
    {
        name: "Cala Pada",
        code: "CPD",
        destinations: [
            { name: "Formentera", code: "FOR" },
        ]
    },
    {
        name: "Es Canar",
        code: "ESC",
        destinations: [
            { name: "Formentera", code: "FOR" },
        ],
    },
    {
        name: "Cala Llonga",
        code: "CLL",
        destinations: [
            { name: "Formentera", code: "FOR" },
        ],
    },
    {
        name: "Formentera",
        code: "FOR",
        destinations: [
            { name: "Santa Eulalia", code: "STA" },
            { name: "Es Canar", code: "ESC" },
            { name: "Cala Llonga", code: "CLL" },
            { name: "Cala Pada", code: "CPD" },
        ],
    }
];
